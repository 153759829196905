import React from "react"
import Layout from "../components/Layout"
import { Container, Row, Col, Accordion, Button } from "react-bootstrap"
import * as styles from "../styles/home.module.css"
import { FcFaq } from "@react-icons/all-files/fc/FcFaq"
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail"
import { AiOutlineForm } from "@react-icons/all-files/ai/AiOutlineForm"
import useSitedata from "../components/useSitedata"
import Headmeta from "../components/Headmeta"

export default function Contact() {
  const faqs = [
    "How can I order?",
    "How can I pay?",
    "How do you survive if you don't get orders?",
    "What's your favourite movie?",
    "What does coordinarts mean?",
    "My question is not listed here.",
  ]
  const faqsAns = [
    "If you want to order an existing artwork, shoot us an email mentioning the artwork's coordinarts (mentioned on the artwork's details page). We will call you back to confirm the order and for other formalities related to payment, delivery, etc. For new artworks, contact us and we will get back to you.",
    "Once your order is confirmed, you can pay us through online modes such as netbanking, UPI, etc. If you are ordering a new artwork, we can start the work with some advance and the rest can be transferred based on milestones and/or after its completion which shall be discussed beforehand. ",
    "Thanks for such as empathetic question. We try to stay afloat by exhausting our emergency fund and then refill it when we have enough orders. With no orders even after exhausting the emergency fund, we hibernate in the Arctic. Don't want us in such a dangerous place? Place an order please!",
    "Wall.E. Although it's not a frequently asked question on this website, not even asked once to be honest, we still included it here. Because why not! We love Wall.E. It's a great movie.",
    "It's a portmanteau of coordinates and arts. Just like each place has unique coordinates, our each artwork has unique coordinarts.",
    "We regret the inconvenience. Let's consider this as an opportunity to talk to each other. Please contact us and we will get back to you.",
  ]

  // console.log(faqs)

  const coordinartsEmail = useSitedata("email")

  return (
    <Layout>
      <Headmeta importedIn='contact | Reach to us for new custom designs or ordering existing designs or any other query' importedUrl='contact' />
      <div>
        <Container className={styles.homeStyles}>
          <Row>
            <Col>
              <h1 className="text-center display-5 pt-5">Tring Tring</h1>
              <p>
                You can contact us to place an order, to know more about us, to
                give a feedback, or just to say hi. You can either email us or
                fill the contact form which takes less than a minute. If you are
                an artist, we would love to connect with you for collaboration.
                Please drop your contact details and we will contact you.
              </p>
            </Col>
          </Row>

          <Row>
            <Col className="justify-content-right text-center">
              <a href={`mailto:${coordinartsEmail}`}>
                <Button variant="outline-dark"> <AiOutlineMail /> Email us</Button>
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-left text-center p-1">
              <a href="https://forms.gle/BDJgk3dPzrXkxCdYA" target="_blank">
                {/* <Button variant="outline-info" className='text-black'>Contact form</Button> */}
                <Button variant="outline-dark" > <AiOutlineForm /> Contact form</Button>
              </a>
            </Col>
          </Row>

            <hr />
          <Row>
            <Col className='text-center'>
              <h5>
                <FcFaq /> Frequently Asked Questions
              </h5>
              <p></p>
              <Accordion>
                {faqs.map((faq, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{faq}</Accordion.Header>
                    <Accordion.Body>{faqsAns[index]} </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
